<template>
  <v-card class="pa-8" width="100%">
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <v-row>
        <v-btn @click="cancel()">ปิด</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="submit()"
          color="primary"
          :disabled="adminUser.isHelper == true"
          >บันทึก</v-btn
        >
      </v-row>
      <h3 class="pa-4" style="font-weight: 800">รายละเอียดออเดอร์</h3>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>หมายเลขคำสั่งซื้อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ transactionNo }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>ต้องติดตาม</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <v-checkbox
            v-model="starFlag"
            label="ติ้กเพื่อติดตาม"
            dense
            color="red"
            :disabled="adminUser.isHelper == true"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row> </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>ชื่อผู้สั่ง</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ user.firstName }} {{ user.lastName }}</span>
        </v-col>
      </v-row>
      <v-row v-if="byAdmin == 1">
        <v-col cols="12" md="3" sm="3"><span>ชื่อลูกค้า</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ customerFirstname }} {{ customerLastname }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>หมายเลขโทรศัพท์</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ user.telNumber }} </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>ประเภทการสั่งซื้อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span v-if="isDelivery">จัดส่งตามที่อยู่</span>
          <span v-if="!isDelivery">เก็บในตู้เซพ</span>
        </v-col>
      </v-row>
      <v-row v-if="isDelivery">
        <v-col cols="12" md="3" sm="3"><span>สถานที่จัดส่ง</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span
            >{{ deliveryAddress.address }} แขวง/ตำบล:
            {{ deliveryAddress.subDistrict }} เขต/อำเภอ:
            {{ deliveryAddress.District }} จังหวัด:
            {{ deliveryAddress.provice }} รหัสไปรษณีย์:
            {{ deliveryAddress.postcode }}
          </span>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" md="3" sm="3"><span>สถานะจัดส่ง</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ deliveryStatus }}</span>
        </v-col>
      </v-row> -->
      <!-- <v-row>
        <v-col cols="12" md="3" sm="3"><span>สั่งเมื่อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ convertDate(createdAt) }}</span>
        </v-col>
      </v-row> -->

      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>สถานะการจ่ายเงิน</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <v-select
            v-if="status != 'SUCCESS' && openStatus != 'ยกเลิก'"
            v-model="status"
            :items="itemsStatus"
            item-text="text"
            item-value="value"
            outlined
            dense
            :disabled="adminUser.isHelper == true"
          ></v-select>
          <v-chip v-if="status == 'SUCCESS'" color="green" dark>
            ชำระเงินสำเร็จ
          </v-chip>
          <v-chip v-if="openStatus == 'ยกเลิก'" color="red" dark>
            ยกเลิก
          </v-chip>
        </v-col>
      </v-row>
      <!-- <v-row dense>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>สถานะการจัดส่ง</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <v-select
            v-model="deliveryStatus"
            :items="itemsDeliveryStatus"
            item-text="text"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>แจ้งเตือนของระบบ</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <p style="color: #f00">{{ couponCode || "-" }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>หมายเหตุ</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <v-text-field
            placeholder="หมายเหตุ"
            v-model="remark"
            dense
            solo
            :disabled="adminUser.isHelper == true"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>แก้ไขโดย</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <span>{{ showUserName || "-" }}</span>
          <!-- <v-text-field placeholder="หมายเหตุ" v-model="remark" dense solo>
          </v-text-field> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>วันที่ซื้อ / เวลา</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <span>{{ convertDate(dateTimeCreatedAt) }}</span>
          <!-- <v-text-field placeholder="หมายเหตุ" v-model="remark" dense solo>
          </v-text-field> -->
        </v-col>
      </v-row>
      <v-row v-if="isWin">
        <v-col cols="12" md="3" sm="3"><span>ถูกรางวัลรวม</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span style="color: green">{{ winPrice }} บาท</span>
        </v-col>
      </v-row>
      <v-row v-if="isWin">
        <v-col cols="12" md="3" sm="3"
          ><span>ชื่อบัญชี ผู้ถูกรางวัล</span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span style="color: green">{{ accountName }}</span>
        </v-col>
      </v-row>
      <v-row v-if="isWin">
        <v-col cols="12" md="3" sm="3"
          ><span>เลขที่บัญชี ผู้ถูกรางวัล</span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span style="color: green">{{ bankNo }} {{ bankName }}</span>
        </v-col>
      </v-row>
      <v-row v-if="isWin">
        <v-col cols="12" md="3" sm="3"><span>ขึ้นรางวัลแล้ว</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <v-checkbox
            v-model="completeWinnings"
            label="ขึ้นรางวัลแล้ว"
            dense
            color="red"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>SLIP โอนเงิน</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <v-img :src="slipImg" style="width: 70%"> </v-img>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mt-1">
        <v-col cols="12" md="12" sm="6" xs="12">
          <v-card outlined class="pa-4">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" class="pt-6">
                <span>อัพโหลดรูปสลิปที่นี่</span>
                <span style="color: red">*</span>
              </v-col>
              <!-- <v-spacer></v-spacer> -->
              <v-col cols="11" class="mt-5 ma-10" id="step-3">
                <v-row align="center" justify="center">
                  <v-img
                    v-model="picture_1"
                    @click="changePic1()"
                    src="@/assets/upload.png"
                    class="mr-3"
                    max-width="30"
                  >
                  </v-img>
                  <span>เลือกรูปภาพ</span>
                </v-row>
                <v-row align="center" justify="center" class="mt-4">
                  <v-col
                    cols="5"
                    md="5"
                    class="pl-5 pr-5 pb-5 row fill-height sortable-list align-center"
                  >
                    <v-card dense light class="pa-1" v-if="showImage">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon small light @click="RemoveImage()"
                          >mdi-close</v-icon
                        >
                      </v-card-actions>

                      <v-img
                        :src="showImage"
                        width="200px"
                        aspect-ratio="0.6"
                        contain
                        @click="newWindow(showImage)"
                      ></v-img>
                    </v-card>
                  </v-col>
                </v-row>
                <input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="image/*"
                  @change="showPicture1($event)"
                  style="display: none"
                  hidden
                  :disabled="adminUser.isHelper == true"
              /></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row v-if="slipImg">
        <v-col cols="12" md="3" sm="3"><span>รูป slip</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <v-img :src="slipImg" max-width="400px" :max-height="auto"></v-img>
        </v-col>
      </v-row> -->
      <h3 class="pt-8" style="font-weight: 800">ออเดอร์ที่สั่ง</h3>
      <v-data-table
        :headers="isWin ? headers1 : headers2"
        :items="lotteryTransaction"
        :items-per-page="15"
        class="elevation-1"
      >
        <template v-slot:[`item.lottery.urlImage`]="{ item }">
          <img class="pa-2" :src="item.lottery.urlImage" height="80px" />
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-row justify="center">
            <v-icon @click="viewProduct(item.product)">mdi-eye</v-icon>
          </v-row>
        </template>
        <template v-slot:[`item.winningPrize`]="{ item }">
          <span
            v-if="item.lottery.winningPrize == 'ไม่ถูกรางวัล'"
            style="color: red"
            >{{ item.lottery.winningPrize }}</span
          >
          <span v-else style="color: green">{{
            item.lottery.winningPrize
          }}</span>
        </template>
      </v-data-table>
      <!-- <v-row v-for="(i, index) in orderProducts" :key="index">
        <v-col cols="2">{{ i.product.productName }}</v-col>
        <v-col cols="2">{{ i.product.description }}</v-col>
        <v-col cols="2"><img :src="i.product.imgUrl" width="100px" /></v-col>
        <v-col cols="2">{{ i.amountTypePrice }} บาท</v-col>
        <v-col cols="1"> {{ i.amount }} {{ i.amountType }}</v-col>
        <v-col cols="1"> {{ i.note }}</v-col>
      </v-row> -->

      <br />
      <br />
      <v-row>
        <v-btn @click="cancel()">ปิด</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="submit()"
          color="primary"
          :disabled="adminUser.isHelper == true"
          >บันทึก</v-btn
        >
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
import moment from "moment";
export default {
  data() {
    return {
      completeWinnings: false,
      winPrice: "",
      isWin: 0,
      count: 1,
      id: "",
      adminName: "",

      status: "",
      itemsStatus: [
        // { text: "ยังไม่ได้อัพโหลดสลิป", value: "BOOKING" },
        { text: "รอการชำระเงิน", value: "PENDING" },
        { text: "รอดำเนินการ", value: "รอดำเนินการ" },
        { text: "อนุมัติ/ยืนยันสลิปถูกต้อง", value: "SUCCESS" },
        { text: "มีปัญหา", value: "มีปัญหา" },
        { text: "ยกเลิก", value: "ยกเลิก" },
        // { text: "ส่งEMSแล้ว", value: "ส่งEMSแล้ว" },
        // { text: "รอการชำระเงิน", value: "รอการชำระเงิน" },
        //{ text: "ชำระเงินสำเร็จ", value: "ชำระเงินสำเร็จ" },
        //{ text: "สั่งซื้อสำเร็จ(อัพโหลดตั๋วแล้ว)", value: "สั่งซื้อสำเร็จ" },
      ],

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      deliveryAddress: "",
      deliveryStatus: "",
      itemsDeliveryStatus: [
        { text: "รอการจัดส่ง", value: "WAITING" },
        { text: "จัดส่งแล้ว", value: "SENT" },
      ],
      isDelivery: false,
      lotteryDate: "",
      lotteryTransaction: [],
      pic1: [],
      picture_1: "",
      showImage: "",
      imageName1: "",
      slipImg: "",
      status: "",
      totalPrice: "",
      user: [],
      couponCode: "-",
      remark: "",
      // orderProducts: [],
      headers1: [
        { text: "transactionId", value: "transactionId" },
        { text: "รูป", value: "lottery.urlImage" },
        { text: "ลอตเตอรี่", value: "lottery.lotteryNo" },
        { text: "จำนวน (ใบ)", value: "lottery.fixedStock" },
        { text: "วันที่ออก", value: "lottery.lotteryDate" },
        { text: "ชุดที่", value: "lottery.set" },
        { text: "งวดที่", value: "lottery.time" },
        { text: "ราคา", value: "lottery.price" },
        { text: "ผลรางวัล", value: "winningPrize" },
      ],
      headers2: [
        { text: "transactionId", value: "transactionId" },
        { text: "รูป", value: "lottery.urlImage" },
        { text: "ลอตเตอรี่", value: "lottery.lotteryNo" },
        { text: "จำนวน (ใบ)", value: "lottery.fixedStock" },
        { text: "วันที่ออก", value: "lottery.lotteryDate" },
        { text: "ชุดที่", value: "lottery.set" },
        { text: "งวดที่", value: "lottery.time" },
        { text: "ราคา", value: "lottery.price" },
        // { text: "ผลรางวัล", value: "winningPrize" },
      ],
      openStatus: "",
      transactionNo: "",
      slipImg: "",
      starFlag: false,

      dateTimeCreatedAt: "",
      showUserName: "",
      byAdmin: 0,
      customerFirstname: "",
      customerLastname: "",
      accountName: "",
      bankName: "",
      bankNo: "",
      // createdAt: "2021-04-24T18:16:52.000Z"
      // id: 139
      // lotteryDate: "2020-08-16"
      // lotteryNo: 309072
      // owner: "admin"
      // price: 80
      // set: 23
      // stock: 0
      // time: 34
      // updatedAt: "2021-06-29T03:25:01.000Z"
      // urlImage: "https://readylotserv
      // lottery: (...)

      adminUser: [],
    };
  },
  created() {
    var orderdata = JSON.parse(
      Decode.decode(localStorage.getItem("orderdata"))
    );
    console.log("orderdata", orderdata);
    this.id = orderdata.id;
    var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
    console.log("userdata", user);
    this.adminUser = user.user;
    console.log("adminUser", this.adminUser);
    this.adminName = user.user.firstName + " " + user.user.lastName;
    // this.orderNo = orderdata.orderNo;
    // this.orderType = orderdata.orderType;
    // this.orderdate = new Date(orderdata.orderdate).toISOString().slice(0, 10);
    // this.totalPrice = orderdata.totalPrice;
    // this.paymentType = orderdata.paymentType;
    // this.status = orderdata.status;
    // this.fullname = orderdata.user.firstname + " " + orderdata.user.lastname;
    // this.tel = orderdata.tel;
    // this.address = orderdata.address;
    this.getOneOrder();
  },
  methods: {
    newWindow(val) {
      window.open(val);
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY HH:mm");
    },
    async getOneOrder() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      console.log(user);
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/` + this.id,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("getOneOrder", response.data);
      const orderdata = response.data.data;
      // this.id = orderdata.id;
      this.deliveryStatus = orderdata.deliveryStatus;
      this.lotteryTransaction = orderdata.lotteryTransaction;
      this.showImage = orderdata.slipImg;
      this.status = orderdata.status;
      this.totalPrice = orderdata.totalPrice;
      this.user = orderdata.user;
      this.isDelivery = orderdata.isDelivery;
      this.couponCode = orderdata.couponCode;
      this.remark = orderdata.remark;
      this.openStatus = orderdata.status;
      if (this.isDelivery) {
        this.deliveryAddress = orderdata.deliveryAddress;
      }
      this.showUserName = orderdata.user.adminName;
      this.transactionNo = orderdata.transactionNo;
      this.slipImg = orderdata.slipImg;
      this.starFlag = orderdata.starFlag;
      this.dateTimeCreatedAt = orderdata.createdAt;
      this.byAdmin = orderdata.byAdmin;
      this.customerFirstname = orderdata.customerFirstname;
      this.customerLastname = orderdata.customerLastname;
      this.completeWinnings = orderdata.completeWinnings;
      this.winPrice = orderdata.winPrice;
      this.accountName = orderdata.user.accountName;
      this.bankName = orderdata.user.bankName;
      this.bankNo = orderdata.user.bankNo;
      this.isWin = orderdata.isWin;
    },
    cancel() {
      window.close();
      // this.$router.push("ManageOrder");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const data = {
          status: this.status,
          deliveryStatus: this.deliveryStatus,
          slipImg: this.pic1,
          adminName: this.adminName,
          remark: this.remark,
          starFlag: this.starFlag,
          completeWinnings: this.completeWinnings,
        };
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/transaction/update/` + this.id,
          data,
          auth
        );
        console.log("update status", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `อัพเดตข้อมูลสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          // this.$router.push("ManageOrder");
          window.location.reload();
        } else {
          this.$swal.fire({
            icon: "error",
            text: `อัพเดตข้อมูลไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    RemoveImage(index, item) {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      // this.product_image.splice(index, 1);
      this.pic1 = [];
      this.picture_1 = "";
      this.slipImg = "";
      this.showImage = "";
      this.imageName1 = "";
    },
  },
};
</script>
